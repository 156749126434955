<template>
  <div class="mystu_page">
    <van-nav-bar left-text="❮返回" @click-left="$router.replace('/index')"></van-nav-bar>
    <div class="mystu_con">
      <div class="box">
        <h3>我的学员</h3>
        <div class="list" v-if="list.length>0">
          <div
            class="item"
            :class="{finish:item.status==2}"
            @click="goStuCourse(item)"
            v-for="item in list"
            :key="item.uid"
          >
            <div class="image">
              <img :src="item.face" alt />
            </div>
            <div class="stu_name">{{ item.name }}</div>
            <div class="tag">
              <span v-if="tagStatus(item.label, 1)" class="ban">班</span>
              <span v-if="tagStatus(item.label, 2)" class="shou">授</span>
              <span v-if="tagStatus(item.label, 3)" class="tea_name">{{item.tea_name}}</span>
            </div>
            <div class="tip">{{statusTxt[item.status]}}</div>
          </div>
          <div class="zw" v-for="i in 10" :key="i"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      statusTxt: ['', '在训', '停训'],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      let res = await this.$http('pad/pad/my-stu')
      // console.log(res);
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.list = res.data instanceof Array ? res.data : []
    },
    tagStatus(arr, i) {
      return arr.some(e => e == i)
    },
    goStuCourse(e) {
      console.log(e);
      // 跳转到学员课程页面
      this.$router.push({
        name: "stuDetail",
        query: {
          uid: e.uid
        },
      });
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.image {
  font-size: 0;
  line-height: 0;
}
img {
  width: 100%;
}
.mystu_page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat;
  min-height: 100vh;
  .mystu_con {
    padding: 0 0.16rem;
    .box {
      background: #fff;
      border-radius: 0.1rem;
      padding: 0.18rem;
      margin-top: 0.2rem;
      min-height: calc(100vh - 1.2rem);
      h3 {
        font-size: 0.18rem;
        color: #666;
        margin: 0;
      }
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .zw {
          width: 1.45rem;
          height: 0;
        }
        .item {
          width: 1.45rem;
          height: 1.8rem;
          background: #fffcfb;
          border-radius: 0.1rem;
          border: 0.01rem solid #ffd9d9;
          text-align: center;
          padding: 0.3rem 0 0.15rem;
          font-size: 0.16rem;
          color: #666666;
          position: relative;
          margin: 0.1rem 0;
          overflow: hidden;
          .image {
            width: 0.68rem;
            height: 0.68rem;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 auto 0.1rem;
            img {
              height: 100%;
            }
          }
          .stu_name {
            margin-bottom: 0.1rem;
          }
          .tag {
            display: flex;
            justify-content: center;
            span {
              padding: 0 0.04rem;
              font-size: 0.14rem;
              margin: 0 0.04rem;
              height: 0.22rem;
              line-height: 0.22rem;
              border-radius: 0.04rem;
              display: inline-block;
              &.ban {
                background: #ffdbdb;
                border: 1px solid #ffbfbe;
                color: #f14947;
              }
              &.shou {
                background: #e5f4ff;
                border: 1px solid #aadaff;
                color: #2aa3ff;
              }
              &.tea_name {
                background: #ffe8c4;
                border: 1px solid #ffcd7d;
                color: #d48300;
              }
            }
          }
          .tip {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            line-height: 0.22rem;
            background: #f14947;
            border-radius: 0px 0.1rem;
            font-size: 0.13rem;
            color: #fff;
            padding: 0 0.1rem;
          }
          &.finish {
            border: none;
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.2);
              position: absolute;
              top: 0;
              left: 0;
            }
            .tip {
              background: #d9d9d9;
            }
          }
        }
      }
    }
  }
}
.van-nav-bar {
  background: none;
  /deep/ .van-nav-bar__left,
  .van-nav-bar__right {
    font-size: 0.16rem;
    color: #333333;
    .van-nav-bar__text {
      color: #333;
    }
  }
  /deep/ .van-nav-bar__left {
    font-size: 0.14rem;
  }
  &::after {
    border: none;
  }
}
</style>